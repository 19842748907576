import React from 'react';
import {graphql} from 'gatsby';
import {
    PageContainerDPC,
    CardElencoDPC,
    FormFiltriDPC,
    ElencoPodcastCardDPC,
    PrintButtonDPC,
    LinksContainerDPC,
    YtEmbedDPC
} from '@dpc-fe/shared';
import DpcPaginator from "@dpc-fe/shared/components/dpc-paginator";
import {getSlug, prop} from "../../functions/functions";
import {buildMenu} from "@dpc-fe/shared/js/shared-functions";
import moment from "moment";
import "moment/locale/it";
import LinksDPC from "@dpc-fe/shared/components/link/dpc-links";

export default ({data, pageContext}) => {

    const eventiPassatiArray = (Array.isArray(data.node.relationships.field_evento_timeline) && data.node.relationships.field_evento_timeline.length > 0)
        ? data.node.relationships.field_evento_timeline.filter(item => {
            return moment(item.field_data_evento).isBefore(moment(), "month");
             // moment().month() > moment(item.field_data_evento).month();
        }).sort((a, b) => {
            return moment(a.field_data_evento).isAfter(moment(b.field_data_evento), "day") ? 1 : -1;
        })
        : [];
    const eventiCorrentiArray = (Array.isArray(data.node.relationships.field_evento_timeline) && data.node.relationships.field_evento_timeline.length > 0)
        ? data.node.relationships.field_evento_timeline.filter(item => {
            return moment().month() === moment(item.field_data_evento).month() && moment().year() === moment(item.field_data_evento).year() ;
        }).sort((a, b) => {
            return moment(a.field_data_evento).isAfter(moment(b.field_data_evento), "day") ? 1 : -1;
        })
        : [];
    const eventiFuturiArray = (Array.isArray(data.node.relationships.field_evento_timeline) && data.node.relationships.field_evento_timeline.length > 0)
        ? data.node.relationships.field_evento_timeline.filter(item => {
            return moment(item.field_data_evento).isAfter(moment(), "month");
            // return moment().month() < moment(item.field_data_evento).month();
        }).sort((a, b) => {
            return moment(a.field_data_evento).isAfter(moment(b.field_data_evento), "day") ? 1 : -1;
        })
        : [];

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const eventiSorted = (Array.isArray(data.node.relationships.field_evento_timeline) && data.node.relationships.field_evento_timeline.length > 0)
    ? data.node.relationships.field_evento_timeline.sort((a, b) => {
        return moment(a.field_data_evento).isAfter(moment(b.field_data_evento)) ? 1 : -1;
    })
    : [];

    const getClassEvento = (granularita, data_evento, isEventoAttivo, isTimelineGenerica) => {
        
        if(isTimelineGenerica){
            return "it-pin-wrapper it-generic";
        }else if(isEventoAttivo){
            return "it-pin-wrapper it-now";
        }else{
            if((((granularita ==="3" || granularita === null) && moment(data_evento).isBefore(moment(), "month")) ||
                (granularita === "2" && moment(data_evento).isBefore(moment(), "day")) || 
                (granularita === "1" && moment(data_evento).isBefore(moment(), "hour")) ||
                (granularita === "0" && moment(data_evento).isBefore(moment()))
                )){
                return "it-pin-wrapper it-evidence";
            }
            else if(( 
                ((granularita ==="3" || granularita === null) && moment(data_evento).isAfter(moment(), "month")) ||
                (granularita === "2" && moment(data_evento).isAfter(moment(), "day")) || 
                (granularita === "1" && moment(data_evento).isAfter(moment(), "hour")) ||
                (granularita === "0" && moment(data_evento).isAfter(moment()))
                )){
                return "it-pin-wrapper";
            }else{
                return "it-pin-wrapper it-now";
            }
        }
    }

    const getSpanEvento = (granularita, data_evento, isEventoAttivo) => {
        if(isEventoAttivo){
            return "Evento in corso";
        }else{
            if(((granularita ==="3" || granularita === null) && moment(data_evento).isBefore(moment(), "month")) ||
            (granularita === "2" && moment(data_evento).isBefore(moment(), "day")) || 
            (granularita === "1" && moment(data_evento).isBefore(moment(), "hour")) ||
            (granularita === "0" && moment(data_evento).isBefore(moment()))
            ){
                return "Evento trascorso";
            }
            else if(( 
                ((granularita ==="3" || granularita === null) && moment(data_evento).isAfter(moment(), "month")) ||
                (granularita === "2" && moment(data_evento).isAfter(moment(), "day")) || 
                (granularita === "1" && moment(data_evento).isAfter(moment(), "hour")) ||
                (granularita === "0" && moment(data_evento).isAfter(moment()))
                )){
                return "Evento futuro";
            }else{
                return "Evento in corso";
            }
        }
    }

    const breadcrumbItem = {
        name: data.node.field_titolo_esteso,
        slug: data.node.fields && data.node.fields.slug ? data.node.fields.slug : null,
        active: true
    }

    const getLink = (link) => {
        if(link.field_link) {
            return [{
                slug: link.field_link.uri,
                title: 'Vai al sito '+link.field_link.title
            }]
        } else if (link.relationships?.field_link_interno) {
            return [{
                slug: getSlug('/' + pageContext.lang + (link.relationships?.field_link_interno?.fields?.slug ), process.env.PORTALE, link.relationships?.field_link_interno?.relationships?.field_sottodominio?.name),
                title: link.relationships?.field_link_interno?.field_titolo_esteso
            }]
        }
    }

    moment.locale('it');

    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={pageContext.menu}
                          title={data.node.title}
                          env={process.env.ENV}
                          lang={pageContext.lang}
                          pageId={data.node.drupal_internal__nid}
                          slugTraduzione={data.node.relationships.field_riferimento_traduzione ? data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                          minisiti={prop}
                          breadcrumbItem={!!data.node?.path?.alias ? null : breadcrumbItem}
                          immagineShare={data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                          abstractShare={data.node.body?.processed ? data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                          slug={process.env.PORTALE_URL + '/' + pageContext.lang + data.node.fields.slug}
                          menuFooterItems={pageContext.menuFooter}
        >
            <div className={"container"}>
                <div className="px-custom">
                    <div className="row">
                        <div className="col-12">
                            <article>
                                <h1>{data.node.field_titolo_esteso}</h1>
                                <div
                                    dangerouslySetInnerHTML={{__html: data.node.body ? data.node.body.processed : ''}}/>
                                <div className="it-timeline-wrapper">
                                {!data.node?.field_timeline_generica &&
                                    <div className="timeline-legend">
                                        <ul>
                                            <li>
                                                <div className="it-pin-wrapper it-evidence">
                                                    <div className="pin-icon"/>
                                                    <span>Evento trascorso</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="it-pin-wrapper it-now">
                                                    <div className="pin-icon"/>
                                                    <span>Evento in corso</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="it-pin-wrapper">
                                                    <div className="pin-icon"/>
                                                    <span>Evento futuro</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    }
                                    <div className="row">
                                    {Array.isArray(eventiSorted) && eventiSorted.map(item => (
                                            <div className="col-12">
                                                <div className="timeline-element">
                                                    { 
                                                    <div className={getClassEvento(data.node.field_granularita, item.field_data_evento, item.field_evento_timeline_attivo, data.node.field_timeline_generica)}>
                                                        <div className="pin-icon">
                                                            <span className="sr-only">{getSpanEvento(data.node.field_granularita, item.field_data_evento, item.field_evento_timeline_attivo)}</span>
                                                        </div>
                                                        <div className="pin-text">
                                                        { data.node.field_granularita === "2" &&
                                                            <span>
                                                            {moment(item.field_data_evento).date()} {capitalizeFirstLetter(moment(item.field_data_evento).format('MMMM'))} {moment(item.field_data_evento).year()}
                                                            </span>   
                                                        }

                                                        { data.node.field_granularita === "0" || data.node.field_granularita === "1" &&
                                                            <span>
                                                            {moment(item.field_data_evento).date()} {capitalizeFirstLetter(moment(item.field_data_evento).format('MMMM'))} {moment(item.field_data_evento).year()} - {moment(item.field_data_evento).hour()}:{moment(item.field_data_evento).minute()}
                                                            </span>   
                                                        }

                                                        { (data.node.field_granularita === "3" || data.node.field_granularita === null) &&
                                                            <span>
                                                            {capitalizeFirstLetter(moment(item.field_data_evento).format('MMMM'))} {moment(item.field_data_evento).year()}
                                                            </span>   
                                                        }
                                                                
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className="card-wrapper">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h2 className="card-title">{item.field_titolo_esteso}</h2>
                                                                {item.relationships.field_immagine_anteprima &&
                                                                <div className="card-img">
                                                                    <figure className="img-wrapper">
                                                                    <figcaption className="sr-only">{item.relationships?.field_immagine_anteprima?.field_didascalia}</figcaption>
                                                                        <img
                                                                            className="img-fluid"
                                                                            alt={item.relationships?.field_immagine_anteprima?.relationships?.image?.field_alt}
                                                                            src={item.relationships?.field_immagine_anteprima?.relationships?.image?.localFile?.publicURL}
                                                                        />
                                                                    </figure>
                                                                </div>
                                                                }
                                                                {item.relationships.field_video_content &&
                                                                <div className="gallery-photo-video-embed">
                                                                    <YtEmbedDPC
                                                                        classe = {"px-5"}
                                                                        titoloSezione={item.relationships.field_video_content.title}
                                                                        url={item.relationships.field_video_content.field_video?.uri}
                                                                        descrizione={item.relationships.field_video_content.field_didascalia} />
                                                                </div>
                                                                }
                                                                <div className="card-text"
                                                                     dangerouslySetInnerHTML={{__html: item.body ? item.body.processed : ''}}/>
                                                                {(item.relationships?.field_link_evento_timeline?.field_link || item.relationships?.field_link_evento_timeline?.relationships?.field_link_interno) &&
                                                                <LinksContainerDPC>
                                                                    <LinksDPC links={getLink(item.relationships.field_link_evento_timeline)}/>
                                                                </LinksContainerDPC>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <PrintButtonDPC/>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainerDPC>
    );
}

export const query = graphql`
  query($slug: String!) {
    node:nodeTimeline (fields: { slug: { eq: $slug } }) {
      title
      field_titolo_esteso
      field_granularita
      field_timeline_generica
      body {
        processed
      }
      fields{
        slug
      }
      relationships {
        field_riferimento_traduzione {
            fields {
              slug
            }
        }
        field_immagine_dettaglio {
            field_alt
            field_didascalia
            relationships {
              image:field_immagine {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        field_evento_timeline {
            field_evento_timeline_attivo
          body {
            processed
          }
          title
          field_titolo_esteso
          field_data_evento
          relationships {
          field_link_evento_timeline {
              field_link {
                title
                uri
              }
              relationships {
                field_link_interno {
                    ...pubblicazioneFragment
                    ...notiziaFragment
                    ...approfondimentoFragment
                    ...normativaFragment
                    ...pageFragment
                    ...bandoDiGaraFragment
                    ...comunicatoStampaFragment
                    ...corsoFragment
                    ...mappaFragment
                    ...timelineFragment
                    ...sezioneConAnticipazioniFragment
                    ...sezioneSenzaAnticipazioniFragment
                    ...elencoApprofondimentiFragment
                    ...elencoGlossariFragment
                    ...elencoAttualitaFragment
                    ...elencoPodcastFragment
                    ...elencoPubblicazioniFragment
                    ...elencoNormativaFragment
                    ...elencoBandiEContrattiFragment
                    ...elencoFotoVideoFragment
                    ...homepageVolontariatoFragment
                    ...homepageRelazioniFragment
                    ...homepageRischiFragment
                    ...homepageNazionaleFragment
                    ...homepageMappeFragment
                    ...homepageGiovaniFragment
                    ...homepageFormazioneFragment
                    ...homepageEventiFragment
                    ...homepageEmergenzeFragment
                    ...homepageDocsFragment
                    ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
                    ...bollettinoCriticitaFragment
                    ...bollettinoVigilanzaFragment
                    ...sezioneAllertaMeteoIdroFragment
                    ...galleriaFotoFragment
                    ...videoFragment
                    ...galleriaFlickrFragment
                }
              }
            }
            field_video_content{
                ...videoFragment
              }
            field_immagine_anteprima {
field_alt
field_didascalia
              relationships {
                image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
              }
            }
          }
        }
      }
    }
  }
`;
